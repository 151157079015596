var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"id":"Scroll","pagination":"","max-items":_vm.itemsPerPage,"search":"","data":_vm.CustomersServices},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(tr,indextr){return _c('tr',{key:indextr,attrs:{"data":tr},on:{"click":function($event){return _vm.editData(tr)}}},[_c('vs-td',[_c('p',{staticStyle:{"font-size":"16px","font-family":"almarai"}},[_vm._v(" "+_vm._s(tr.unitName)+" ")])]),_c('vs-td',[_c('p',{staticStyle:{"font-size":"16px","font-family":"almarai"}},[(
                      tr.serviceType != null && tr.serviceType.nameAr != null
                    )?_c('span',[_vm._v(_vm._s(tr.serviceType.nameAr))]):_c('span',[_vm._v(_vm._s(_vm.$t("IsOther")))])])]),_c('vs-td',[(tr.statusID == 2)?_c('span',{style:({

       backgroundColor: 'orange', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element
    })},[_vm._v(" "+_vm._s(_vm.$t("inprocess"))+" ")]):(tr.statusID == 3)?_c('span',{style:({

      backgroundColor: '#28C76F', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element
    })},[_vm._v(" "+_vm._s(_vm.$t("Finished"))+" ")]):_c('span',{style:({
      backgroundColor: 'crimson', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element

    })},[_vm._v(" "+_vm._s(_vm.$t("pending"))+" ")])]),_c('vs-td',[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(tr.createdDate)))])]),_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('router-link',{attrs:{"to":{
                    name: 'CustomersServiceDetails',
                    params: { ID: tr.id },
                  }}},[_c('feather-icon',{attrs:{"icon":"EditIcon","svgClasses":"w-5 h-5 hover:text-primary stroke-current"},on:{"click":function($event){$event.stopPropagation();return _vm.AddOrEditData(tr)}}})],1)],1)],1)}),0)]}}])},[_c('template',{slot:"thead"},[_c('vs-th',{staticStyle:{"font-size":"16px","font-family":"almarai","color":"green"},attrs:{"sort-key":"UnitName"}},[_vm._v(_vm._s(_vm.$t("UintName")))]),_c('vs-th',{staticStyle:{"font-size":"16px","font-family":"almarai","color":"green"},attrs:{"sort-key":"ServiceType"}},[_vm._v(_vm._s(_vm.$t("ServiceType")))]),_c('vs-th',{staticStyle:{"font-size":"16px","font-family":"almarai","color":"green"},attrs:{"sort-key":"ServiceStatus"}},[_vm._v(_vm._s(_vm.$t("ServiceStatus")))]),_c('vs-th',{staticStyle:{"font-size":"16px","font-family":"almarai","color":"green"},attrs:{"sort-key":"serviceDate"}},[_vm._v(_vm._s(_vm.$t("serviceDate")))]),_c('vs-th',{staticStyle:{"font-size":"16px","font-family":"almarai","color":"crimson"}},[_vm._v(_vm._s(_vm.$t("Action")))])],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }