<template>
  <div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="CustomersServices"
      >
      <template slot="thead">
  <!-- <vs-th sort-key="Name" style="font-size: 15px; color: #007bff;">{{ $t("Owner") }}</vs-th>
  <vs-th sort-key="PhoneNumber" style="font-size: 15px; color: #007bff;">{{ $t("PhoneNumber") }}</vs-th> -->
  <vs-th sort-key="UnitName" style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("UintName") }}</vs-th>
  <!-- <vs-th sort-key="OwnerType" style="font-size: 15px; color: #007bff;">{{ $t("UnitModel") }}</vs-th> -->
  <vs-th sort-key="ServiceType" style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("ServiceType") }}</vs-th>
  <vs-th sort-key="ServiceStatus" style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("ServiceStatus") }}</vs-th>
  <vs-th sort-key="serviceDate" style="font-size: 16px;font-family:almarai ; color: green;">{{ $t("serviceDate") }}</vs-th>
  <!-- <vs-th sort-key="serviceTime" style="font-size: 15px; color: #007bff;">{{ $t("serviceTime") }}</vs-th> -->
  <!-- <vs-th sort-key="executionTieme" style="font-size: 15px; color: #007bff;">{{ $t("executionTieme") }}</vs-th> -->
  <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;">{{ $t("Action") }}</vs-th>
</template>


        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
            >
              <!-- <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.ownerName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.phoneNumber }}
                </p>
              </vs-td> -->
              <vs-td>
                <p style="font-size: 16px;font-family:almarai;">
                  {{ tr.unitName }}
                </p>
              </vs-td>
              <!-- <vs-td>
                <p class="product-name font-medium truncate">
                <span v-if="tr.uintModelName != null">
                  {{ tr.uintModelName }}
                </span>
                <span v-if="tr.uintModelName == null">
                  {{ $t("NotExit") }}
                </span>
                </p>
              </vs-td> -->
              <vs-td>
                <p style="font-size: 16px;font-family:almarai;">
                  <span
                    v-if="
                      tr.serviceType != null && tr.serviceType.nameAr != null
                    "
                    >{{ tr.serviceType.nameAr }}</span
                  >
                  <span v-else>{{ $t("IsOther") }}</span>
                </p>
              </vs-td>
              <vs-td>
  <!-- In Process Status -->
  <span
    v-if="tr.statusID == 2"
    :style="{

       backgroundColor: 'orange', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element
    }"
  >
    {{ $t("inprocess") }}
  </span>

  <!-- Finished Status -->
  <span
    v-else-if="tr.statusID == 3"
    :style="{

      backgroundColor: '#28C76F', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element
    }"
  >
    <!-- Checkmark icon -->

    {{ $t("Finished") }}
  </span>

  <!-- Pending Status -->
  <span
    v-else
    :style="{
      backgroundColor: 'crimson', // Green color for success status
      padding: '8px 12px', // Adjust padding for better appearance
      borderRadius: '20px', // Rounded corners
      display: 'inline-block',
      color: 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
      transition: 'transform 0.2s ease-in-out', // Smooth transition
      cursor: 'pointer', // Cursor pointer to indicate interactivity
      position: 'relative' // Add position relative for pseudo-element

    }"
  >
    {{ $t("pending") }}
  </span>
              </vs-td>



              <vs-td>
                <span> {{ formatDate(tr.createdDate) }}</span>
              </vs-td>
              <!-- <vs-td>
                <span> {{ formatDateHours(tr.createdDate) }}</span>
              </vs-td> -->

              <vs-td class="whitespace-no-wrap">
                               <router-link
                  :to="{
                    name: 'CustomersServiceDetails',
                    params: { ID: tr.id },
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    @click.stop="AddOrEditData(tr)"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleCustomersService from "@/store/CustomersService/moduleCustomersService.js";
// import { LoaderIcon } from 'vue-feather-icons'
// import { CheckIcon } from 'vue-feather-icons'
export default {
  components: {
    // LoaderIcon,
    // CheckIcon
  },
  data() {
    return {
      itemsPerPage: 10,
     // CustomersServices: [],
      baseURL: domain,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    CustomersServices() {
      return this.$store.state.CustomersServiceList.CustomersServices;
    },
  },

  methods: {
    formatDateHours(dateString) {
      const date = new Date(dateString);
      return date.toLocaleTimeString(); // Adjust format as needed
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Adjust format as needed
    },

    AddOrEditData(data) {
      debugger;
      if (data.id == undefined) {
        data.id = 0;
      }
      this.$router.push(`/CustomersService/CustomersServiceDetails/${data.id}`);
    },
    Finished(data) {
      debugger;

        var Id =  data.id;
      this.$store
        .dispatch("CustomersServiceList/Finished", Id)
        .then(() => {
          this.$store.dispatch("CustomersServiceList/SearchCustomersService", this.search)
            debugger;
            this.$store.dispatch("CustomersServiceList/fetchDataListItems")
          this.cancelsuccess();
        });
    },

    processing(data) {
      debugger;
        var Id =  data.id;
      this.$store
        .dispatch("CustomersServiceList/processing", Id)
        .then(() => {
          debugger;
          this.$store.dispatch("CustomersServiceList/SearchCustomersService", this.search)
          this.$store.dispatch("CustomersServiceList/fetchDataListItems")
        debugger;
          this.approvedsuccess();
        });
    },
     cancelsuccess() {
      this.$vs.notify({
        color: "success",
        title: "Successfuly UnBlocked",
      //  text: this.$t("CancelSuccessfully"),
      });
    },

    approvedsuccess() {
      this.$vs.notify({
        color: "success",
        title: "Successfully Blocked",
        //text: this.$t("ApprovedSuccessfully"),
      });
    },

    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });

      this.$store
        .dispatch("CustomersServiceList/SearchCustomersService", this.search)
    },

    // deleteData(data) {
    //   debugger;
    //   this.$store
    //     .dispatch("CustomersServiceList/DeleteCustomersService", data)

    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
  },
  created() {
    if (!moduleCustomersService.isRegistered) {
      this.$store.registerModule(
        "CustomersServiceList",
        moduleCustomersService
      );
      moduleCustomersService.isRegistered = true;
    }
    this.$store
        .dispatch("CustomersServiceList/SearchCustomersService", this.search)
    this.$store
        .dispatch("CustomersServiceList/fetchDataListItems")

    // this.fetchCustomersServices();
    // this.handleSearch(this.search);
  },
  mounted() {
    this.isMounted = true;
    this.fetchOwnerTypeOptions();
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
